<template>
    <v-snackbar
        v-model="snackbarOpen"
        :timeout="timeout"
        color="success"
    >
        <div style="display:flex; flex-direction:column; align-items:center;" v-if="text!=undefined">
            <p style="color:white;">{{addedLabel[languageId]}} <strong>{{ text.split("|")[languageId] }}</strong> {{toCartLabel[languageId]}}</p>
            <a class="underline" style="color:white; font-weight: bold;" @click="$router.push('/cart')">{{goToShoppingCartLabel[languageId]}}</a>
        </div>
        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="close()"
            icon
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
	export default {
		name: 'AddedElementSnackbar',
		components: {  },
        props: ['text', 'snackbar'],
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            timeout: 3000,
            addedLabel:["Se agregó","Added"],
            toCartLabel:["al carrito.", "to shopping cart."],
            goToShoppingCartLabel:["Continuar al carrito de compras.", "Continue to shopping cart."],
		}),
        beforeDestroy(){
            this.close();
        },
        computed: {
            ...mapState("shoppingCart", ["languageId",]),
            snackbarOpen:{
                get(){
                    return this.snackbar
                },
                set(){
                    this.$emit("snackEnd", false);
                }
            },
        },
        methods:{
            close(){
                this.$emit("snackEnd", false);
            },
        }
	}
</script>
