<template>
<div>
    <v-container  fluid style="padding:0px; margin-bottom: -128px;" class="text-center desktop-size"  v-if="dataReady && dataOk && isSmallScreen=='Desktop'">
		<v-row no-gutters class="text-center" style="margin-top:-128px; height:128px;">
			<v-col cols="12" style="background-color:#303030;">
			</v-col>
        </v-row>
        <v-row no-gutters class="text-center" justify="center">
            <div class="colorYellow poppinsRegularFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center;">
                <a class="cursorPointer uppercase mr-2 colorYellow" :href="'/'">
                    {{homeButtonLabel[languageId]}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <a class="cursorPointer uppercase ml-2 mr-2 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}`">
                    {{artPiecesButtonLabel[languageId]}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <a class="uppercase ml-2 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}/${collectionLabel.split('|')[languageId].replace(/\s/g,' ')}`">
                    {{collectionLabel.split("|")[languageId].replace(/\s/g," ")}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="uppercase ml-1 poppinsSemiboldFont">
                    {{display.title.split("|")[languageId].replace(/\s/g," ")}}
                </span>
            </div>
        </v-row>
		<v-row no-gutters class="text-center" style="background-color:#F6F6E9; height:fit-content;">
            <div style="display:flex; flex-direction: column; justify-content: center; padding:150px 100px 50px 100px; width:100%">
                <div style="width:100%; display:flex; flex-direction: row; ">
                    <div class="mr-4" style="height:fit-content; width:1080px; display:flex; align-items:center; justify-content:center;">
                        <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-right:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('back','selectedImage')">
                            &#60;
                        </a>
                        <v-img :src="require(`../assets/${selectedImage.base64}`)" position="center" contain max-height="720" style="position:relative;" />
                        <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-left:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('forward','selectedImage')">
                            &#62;
                        </a>
                    </div>
                    <div class="ml-4" style="height:fit-content; width:580px;">
                        <v-form ref="form" lazy-validation >
                        <div style=" display:flex; flex-direction: column; align-items:center; justify-content:center; width:100%;">

                            <div class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px; padding:0px; margin-bottom:-35px; margin-top:-25px;"  v-if="!isComingSoon && isNew">{{newLabel[languageId]}}</span>
                                <h1 v-if="display.title!=undefined" class="colorYellow poppinsboldFont" style="font-size:29px; text-align:left;">{{display.title.split("|")[languageId].replace(/\s/g," ")}}</h1>
                                <h3 v-if="display.price!=undefined" class="mb-6 colorDarkBrown poppinsboldFont"  style="font-size:22px;">$ {{(getPrice(display)* form.quantity).toLocaleString()}}</h3>
                            </div>
                            <div style=" display:flex; flex-direction: row; align-items:center;  width:100%;" v-if="!getOutOfStock()">
                                <v-text-field
                                    v-if="!isComingSoon"
                                    v-model="form.quantity"
                                    type="number"
                                    dense
                                    min=1 
                                    :max="getMaxProductionBySize()"
                                    class="form-control"
                                    style="max-width:50px;"
                                    @keydown="positiveQuantityValidator($event)"
                                ></v-text-field>
                                <v-btn v-if="!isComingSoon" color="secondary" @click="addToCart()" class="ml-2" >
                                    {{addToCartLabel[languageId]}}
                                </v-btn>
                            </div>
                            <div class="poppinsboldFont" style=" display:flex; flex-direction: row; align-items:center;  width:100%; color:#FF5C35; font-size:24px;" v-if="getOutOfStock()">
                                {{outOfStockLabel[languageId]}}
                            </div>
                            <div v-if="isComingSoon" class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px; padding:0px;" >{{comingSoonLabel[languageId]}}</span>
                            </div>
                            <div v-if="!isComingSoon" class="mt-5" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select 
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.size"
                                            :items="artPieceSizes(display.sizes)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="sizeError?sizeErrorMessage[languageId]:[]"
                                            @click="sizeError=false"
                                            style="max-width:230px;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectSize"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{sizeLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{sizeHint[languageId]}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.printedMedium"
                                            :items="artPiecesPrintedMediums(display.printedMediums)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="printedMediumError?printedMediumErrorMessage[languageId]:[]"
                                            @click="printedMediumError=false"
                                            style="max-width:230px;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{printedMediumLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{printedMediumHint[languageId]}}</span>
                                </v-tooltip>
                            </div>
                            <div v-if="!isComingSoon" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.frame"
                                            :items="artPiecesFrames(display.frames)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="frameError?frameErrorMessage[languageId]:[]"
                                            @click="frameError=false"
                                            style="max-width:230px;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectFrame"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{frameLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{frameHint[languageId]}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.liner"
                                            :items="artPiecesLiners(display.liners)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="linerError?linerErrorMessage[languageId]:[]"
                                            @click="linerError=false"
                                            style="max-width:230px;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectLiner"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{linerLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{linerHint[languageId]}}</span>
                                </v-tooltip>
                            </div>
                            <div v-if="display.price!=null">
                                <div style="border:1px solid #D79A2B; background-color:#ECECE4; width:100%; height:contain;" class="py-4" v-if="display.price!=null">
                                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px;">{{simulatorTitle1[languageId]}}</h2>
                                    <p class="colorYellow poppinsRegularFont" style="font-size: 22px;text-transform:uppercase; margin:0px;">{{simulatorTitle2[languageId]}}</p>
                                    <p class="mb-8 colorGray poppinsRegularFont" style="font-size:18px;">{{simulatorTitle3[languageId]}}</p>
                                </div>
                                <div style="width:100%;margin-top:-18px;">
                                    <a href="/simulator">
                                        <v-btn color="secondary"  @click="$router.push('/simulator')">{{simulatorButton[languageId]}}</v-btn>
                                    </a>
                                </div>
                            </div>
                            <div class="mt-5 poppinsExtraLightFont colorDarkGrey" style="font-size:18px; display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                <p style="text-align:left;" v-html="display.description.split('|')[languageId].replace(/\s/g,' ')"></p>
                            </div>
                        </div>
                        </v-form>
                    </div>
                </div>
                <div class="mt-4" style="width:100%; display:flex; flex-direction: row; ">
                    <div class="mr-4" style="height:auto;  width:1080px; display:flex; flex-direction: row; align-items:flex-start; justify-content:flex-start; overflow:auto;" v-if="!reloadDisplayImages">
                        <img class="mr-1" v-for="(image,index) in displayImages()" :key="index" :src="require(`../assets/${image.base64}`)" :alt="image.alt.includes('|')?image.alt.split('|')[languageId]:image.alt" style="max-height:280px;max-width:300px;" @click="selectImage(image,index)"/>
                    </div>
                    <div class="ml-4" style="height:auto;  width:580px; ">
                        <div class="mr-4" style="background-color:white; height:fit-content; width:fit-content; display:flex; flex-direction: column; align-items:flex-start; justify-content:flex-start; padding:20px;">
                            <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{skuLabel[languageId]}}: </span>{{display.id}}</div>
                            <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{categoryLabel[languageId]}}: </span>{{display.type}}</div>
                            <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{shareLabel[languageId]}}: </span>
                                <a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/">
                                    <v-icon class="colorDarkBrown">
                                        mdi-instagram
                                    </v-icon>
                                </a>
                                <a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                                    <v-icon class="colorDarkBrown">
                                        mdi-facebook
                                    </v-icon>
                                </a>
                                <a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                                    <v-icon class="colorDarkBrown">
                                        mdi-pinterest
                                    </v-icon>
                                </a>
                                <a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                                    <v-icon class="colorDarkBrown">
                                        mdi-whatsapp
                                    </v-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:#F4F8F8; height:fit-content; padding:100px">
            <div style="width:1080px; display:flex; flex-direction: column; justify-content: flex-start; align-items:flex-start;">
                <div class="colorYellow poppinsLightFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center; font-size:22px;">
                    <span class="cursorPointer uppercase mr-1 noSelect" :class="historySelected?'poppinsboldFont':''" @click="historySelected=true; infoSelected=false; reviewSelected=false;">
                        {{littleHistoryLabel[languageId]}}
                    </span>
                    <span class="ml-1 mr-1" style="cursor: context-menu noSelect">/</span>
                    <span class="cursorPointer uppercase ml-1 mr-1 noSelect" :class="infoSelected?'poppinsboldFont':''" @click="historySelected=false; infoSelected=true; reviewSelected=false;">
                        {{additionalInformationLabel[languageId]}}
                    </span>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:16px; padding-top:20px;" v-if="historySelected">
                    <p style="text-align:left;">{{display.history.split("|")[languageId].replace(/\s/g," ")}}</p>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:16px; padding-top:20px;" v-if="infoSelected">
                    <div v-for="(data,index) in display.exif.split('|')" :key="index" >
                        <p style="text-align:left; margin:0px;" v-if="index==0"><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{exifDate}}</p>
                        <p style="text-align:left; margin:0px;" v-else><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{data}}</p>
                    </div>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+spacesBackgroundImages[collectionId]+')'" style="background-color:cornsilk; height:900px; padding-bottom:40px">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto;">
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:1080px;">
                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:80px; margin-bottom:-30px">{{idealLabel[languageId]}}</h2>
                    <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:30px;">{{spacesLabel[languageId]}}</h2>
                </div>
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:1080px;">
                    <v-img v-if="selectMuckup()" :src="require(`../assets/${selectMuckup().base64}`)" position="center" contain max-height="720"/>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:white; height:fit-content; padding:100px">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto;">
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:1600px;">
                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:80px; margin-bottom:-30px">{{moreLabel[languageId]}}</h2>
                    <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:30px;">{{collectionsForYouLabel[languageId]}}</h2>
                </div>
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; width:1600px;" v-if="collectionNamesOk">
                    <div :style="'background-image: url('+require(`../assets/ArtDetails/${artPieceFeaturedCollectionIMGBig}`)+')'" class="header backGroundImage" style="height:600px; display:flex; flex-direction: column; justify-content:space-between;">
                        <div>
                            <h2 class="colorWhite lusciousLifestylesScriptFont ml-4" style="font-size:110px;">{{collectionNames.find(x=>x.id==artPieceFeaturedCollection).name.split("|")[languageId]}}</h2>
                        </div>
                        <div>
                            <!-- <v-img :src="require(`../assets/${getCollectionImage(artPieceFeaturedCollection,0,'Portrait')}`)" position="center" contain max-height="150" /> -->
                        </div>
                        <div style="display:flex; justify-content:flex-end; align-items: flex-end; padding:20px">
                            <a class="colorWhite poppinsBoldFont uppercase shopNowButton text-shadow background-darker" style="font-size: 24px; padding:5px 20px;" :href="goToCollection(artPieceFeaturedCollection)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                    <p class="colorDarkBrown poppinsRegularFont verticalText uppercase" style="font-size:24px; text-align:center; padding:0px 20px;">{{featuredCollectionLabel[languageId]}}</p>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="height:fit-content;">
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_04.jpg`)+')'" style="width:50%; background-color:beige; padding:50px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <v-img :src="require(`../assets/ArtDetails/${artPieceCollection1IMGBig}`)" position="center" contain max-height="550" class="mt-8" />
                        <div style="padding:20px; width:790px; margin:auto;">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-2" style="font-size:60px;">{{collectionNames.find(x=>x.id==artPieceCollection1).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton" style="font-size: 24px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection1)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_05.jpg`)+')'" style="width:50%; background-color:lightgray; padding:50px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div  class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <v-img :src="require(`../assets/ArtDetails/${artPieceCollection2IMGBig}`)" position="center" contain max-height="550" class="mt-8" />
                        <div style="padding:20px; width:790px; margin:auto;">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-2" style="font-size:60px;">{{collectionNames.find(x=>x.id==artPieceCollection2).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton" style="font-size: 24px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection2)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

		<footerComponent :backgroundImage="footerImage"></footerComponent>
    </v-container>
    
    <v-container  fluid style="padding:0px; margin-bottom: -128px;" class="text-center tablet-size"  v-if="dataReady && dataOk && isSmallScreen=='Tablet'">
		<v-row no-gutters class="text-center" style="margin-top:-128px; height:128px;">
			<v-col cols="12" style="background-color:#303030;">
			</v-col>
        </v-row>
        <v-row no-gutters class="text-center" justify="center">
            <div class="colorYellow poppinsLightFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center; font-size:18px;">
                <a class="cursorPointer uppercase mr-2 colorYellow" :href="'/'">
                    {{homeButtonLabel[languageId]}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <a class="cursorPointer uppercase ml-2 mr-2 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}`">
                    {{artPiecesButtonLabel[languageId]}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <a class="uppercase ml-2 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}/${collectionLabel.split('|')[languageId].replace(/\s/g,' ')}`">
                    {{collectionLabel.split("|")[languageId].replace(/\s/g," ")}}
                </a>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="uppercase ml-1 poppinsSemiboldFont">
                    {{display.title.split("|")[languageId].replace(/\s/g," ")}}
                </span>
            </div>
        </v-row>
		<v-row no-gutters class="text-center" style="background-color:#F6F6E9; height:fit-content">
            <div style="display:flex; flex-direction: column; justify-content: center; padding:70px 30px 50px 30px; width:100%">
                <div style="width:100%; display:flex; flex-direction: row; justify-content:space-between;">
                    <div class="mr-4" style="height:fit-content; display:flex; flex-direction:column; align-items:center; justify-content:center;" :style="`width:${imageSizeWidth}px;`">
                        <div style="display:flex; align-items:center; justify-content:center;"  :style="`width:${imageSizeWidth}px;`">
                            <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-right:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('back','selectedImage')">
                                &#60;
                            </a>
                            <v-img :src="require(`../assets/${selectedImage.base64}`)" position="center" contain :max-height="maxImageSizeHeight" style="position:relative;" />
                            <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-left:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('forward','selectedImage')">
                                &#62;
                            </a>
                        </div>
                        <div class="mt-8" style="height:auto; display:flex; flex-direction: row; align-items:flex-start; justify-content:flex-start; overflow:auto;"  :style="`width:${imageSizeWidth}px;`" v-if="!reloadDisplayImages">
                            <img class="mr-1" v-for="(image,index) in displayImages()" :key="index" :src="require(`../assets/${image.base64}`)" :alt="image.alt.includes('|')?image.alt.split('|')[languageId]:image.alt" style="max-height:280px; max-width:300px;" @click="selectImage(image,index)"/>
                        </div>
                    </div>
                    <div class="ml-4" style="height:fit-content; width:432px;" >
                        <v-form ref="form" lazy-validation >
                            <div style=" display:flex; flex-direction: column; align-items:center; justify-content:center; width:100%;">

                                <div class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:66px; padding:0px; margin-bottom:-25px;" v-if="!isComingSoon && isNew">{{newLabel[languageId]}}</span>

                                    <h1 v-if="display.title!=undefined" class="colorYellow poppinsboldFont" style="font-size:26px; text-align:left;">{{display.title.split("|")[languageId].replace(/\s/g," ")}}</h1>
                                    <h3 v-if="display.price!=undefined" class="mb-6 colorDarkBrown poppinsboldFont" style="font-size:22px;">$ {{(getPrice(display)* form.quantity).toLocaleString()}}</h3>
                                </div>
                                <div style=" display:flex; flex-direction: row; align-items:center;  width:100%;" v-if="!getOutOfStock()">
                                    <v-text-field
                                        v-if="!isComingSoon"
                                        v-model="form.quantity"
                                        type="number"
                                        dense
                                        min=1 
                                        :max="getMaxProductionBySize()"
                                        class="form-control"
                                        style="max-width:50px;"
                                        @keydown="positiveQuantityValidator($event)"
                                    ></v-text-field>
                                    <v-btn v-if="!isComingSoon" color="secondary" @click="addToCart()" class="ml-2" >
                                        {{addToCartLabel[languageId]}}
                                    </v-btn>
                                </div>
                                <div class="poppinsboldFont" style=" display:flex; flex-direction: row; align-items:center;  width:100%; color:#FF5C35; font-size:22px;" v-if="getOutOfStock()">
                                    {{outOfStockLabel[languageId]}}
                                </div>
                                <div v-if="isComingSoon" class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:66px; padding:0px;">{{comingSoonLabel[languageId]}}</span>
                                </div>
                                <div v-if="!isComingSoon" class="mt-5" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-select 
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="form.size"
                                                :items="artPieceSizes(display.sizes)"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="sizeError?sizeErrorMessage[languageId]:[]"
                                                @click="sizeError=false"
                                                style="max-width:200px;"
                                                color="#848484"
                                                item-color="#848484"
                                                class="poppinsLightFont"
                                                @change="selectSize"
                                            >
                                                <template v-slot:label>
                                                    <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{sizeLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                </template>
                                            </v-select>
                                        </template>
                                        <span>{{sizeHint[languageId]}}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-select
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="form.printedMedium"
                                                :items="artPiecesPrintedMediums(display.printedMediums)"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="printedMediumError?printedMediumErrorMessage[languageId]:[]"
                                                @click="printedMediumError=false"
                                                style="max-width:200px;"
                                                color="#848484"
                                                item-color="#848484"
                                                class="poppinsLightFont"
                                            >
                                                <template v-slot:label>
                                                    <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{printedMediumLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                </template>
                                            </v-select>
                                        </template>
                                        <span>{{printedMediumHint[languageId]}}</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="!isComingSoon" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-select
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="form.frame"
                                                :items="artPiecesFrames(display.frames)"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="frameError?frameErrorMessage[languageId]:[]"
                                                @click="frameError=false"
                                                style="max-width:200px;"
                                                color="#848484"
                                                item-color="#848484"
                                                class="poppinsLightFont"
                                                @change="selectFrame"
                                            >
                                                <template v-slot:label>
                                                    <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{frameLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                </template>
                                            </v-select>
                                        </template>
                                        <span>{{frameHint[languageId]}}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-select
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="form.liner"
                                                :items="artPiecesLiners(display.liners)"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="linerError?linerErrorMessage[languageId]:[]"
                                                @click="linerError=false"
                                                style="max-width:200px;"
                                                color="#848484"
                                                item-color="#848484"
                                                class="poppinsLightFont"
                                                @change="selectLiner"
                                            >
                                                <template v-slot:label>
                                                    <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{linerLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                </template>
                                            </v-select>
                                        </template>
                                        <span>{{linerHint[languageId]}}</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="display.price!=null">
                                    <div style="border:1px solid #D79A2B; background-color:#ECECE4; width:100%; height:contain;" class="py-4" v-if="display.price!=null">
                                        <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px;">{{simulatorTitle1[languageId]}}</h2>
                                        <p class="colorYellow poppinsRegularFont" style="font-size: 22px;text-transform:uppercase; margin:0px;">{{simulatorTitle2[languageId]}}</p>
                                        <p class="mb-8 colorGray poppinsRegularFont" style="font-size:18px;">{{simulatorTitle3[languageId]}}</p>
                                    </div>
                                    <div style="width:100%;margin-top:-18px;">
                                        <a href="/simulator">
                                            <v-btn color="secondary"  @click="$router.push('/simulator')">{{simulatorButton[languageId]}}</v-btn>
                                        </a>
                                    </div>
                                </div>
                                <div class="mt-5 colorDarkGrey poppinsExtraLightFont" style="font-size:18px; display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                    <p style="text-align:left;" v-html="display.description.split('|')[languageId].replace(/\s/g,' ')"></p>
                                </div>
                            </div>
                        </v-form>
                        <div class="mt-4" style="height:auto;  width:432px; ">
                            <div class="mr-4" style="background-color:white; height:fit-content; width:fit-content; display:flex; flex-direction: column; align-items:flex-start; justify-content:flex-start; padding:20px;">
                                <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{skuLabel[languageId]}}: </span>{{display.id}}</div>
                                <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{categoryLabel[languageId]}}: </span>{{display.type}}</div>
                                <div class="colorDarkGrey poppinsExtraLightFont" ><span class="poppinsSemiboldFont uppercase">{{shareLabel[languageId]}}: </span>
                                    <a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/">
                                        <v-icon class="colorDarkBrown">
                                            mdi-instagram
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                                        <v-icon class="colorDarkBrown">
                                            mdi-facebook
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                                        <v-icon class="colorDarkBrown">
                                            mdi-pinterest
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                                        <v-icon class="colorDarkBrown">
                                            mdi-whatsapp
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" style="width:100%; display:flex; flex-direction: row; justify-content:space-between;">
                    
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:#F4F8F8; height:fit-content; padding:100px 50px">
            <div style="width:920px; display:flex; flex-direction: column; justify-content: flex-start; align-items:flex-start; margin:auto;">
                <div class="colorYellow poppinsLightFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center; font-size:21px;">
                    <span class="cursorPointer uppercase mr-1 noSelect" :class="historySelected?'poppinsboldFont':''" @click="historySelected=true; infoSelected=false; reviewSelected=false;">
                        {{littleHistoryLabel[languageId]}}
                    </span>
                    <span class="ml-1 mr-1" style="cursor: context-menu noSelect">/</span>
                    <span class="cursorPointer uppercase ml-1 mr-1 noSelect" :class="infoSelected?'poppinsboldFont':''" @click="historySelected=false; infoSelected=true; reviewSelected=false;">
                        {{additionalInformationLabel[languageId]}}
                    </span>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:17px; padding-top:20px;" v-if="historySelected">
                    <p style="text-align:left;">{{display.history.split("|")[languageId].replace(/\s/g," ")}}</p>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:17px; padding-top:20px;" v-if="infoSelected">
                    <div v-for="(data,index) in display.exif.split('|')" :key="index" >
                        <p style="text-align:left; margin:0px;" v-if="index==0"><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{exifDate}}</p>
                        <p style="text-align:left; margin:0px;" v-else><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{data}}</p>
                    </div>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+spacesBackgroundImages[collectionId]+')'" style="background-color:cornsilk; height:fit-content; padding-bottom:40px">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto;" class="px-4">
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:100%;">
                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:80px; margin-bottom:-30px">{{idealLabel[languageId]}}</h2>
                    <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:30px;">{{spacesLabel[languageId]}}</h2>
                </div>
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:100%;">
                    <v-img v-if="selectMuckup()" :src="require(`../assets/${selectMuckup().base64}`)" position="center" contain max-height="720" :max-width="widthSize-32"/>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:white; height:fit-content; padding:50px">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto;">
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:100%;">
                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:80px; margin-bottom:-30px">{{moreLabel[languageId]}}</h2>
                    <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:30px;">{{collectionsForYouLabel[languageId]}}</h2>
                </div>
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; " v-if="collectionNamesOk">
                    <div :style="'background-image: url('+require(`../assets/ArtDetails/${artPieceFeaturedCollectionIMGBig}`)+')'" class="header backGroundImage" style="height:350px; display:flex; flex-direction: column; justify-content:space-between; width:900px;">
                        <div>
                            <h2 class="colorWhite lusciousLifestylesScriptFont ml-4" style="font-size:80px;">{{collectionNames.find(x=>x.id==artPieceFeaturedCollection).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                        </div>
                        <div>
                            <!-- <v-img :src="require(`../assets/${getCollectionImage(artPieceFeaturedCollection,0,'Portrait')}`)" position="center" contain max-height="150" /> -->
                        </div>
                        <div style="display:flex; justify-content:flex-end; align-items: flex-end; padding:20px">
                            <a class="colorWhite poppinsBoldFont uppercase shopNowButton-tablet text-shadow background-darker" style="font-size: 22px; padding:5px 20px;" :href="goToCollection(artPieceFeaturedCollection)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                    <p class="colorDarkBrown poppinsRegularFont verticalText uppercase" style="font-size:20px; text-align:center; padding:0px 20px;">{{featuredCollectionLabel[languageId]}}</p>
                </div>
                
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="height:fit-content;">
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_04.jpg`)+')'" style="width:50%; background-color:beige; padding:30px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <v-img :src="require(`../assets/ArtDetails/${artPieceCollection1IMGBig}`)" position="center" contain max-height="480" class="mt-8" />
                        <div style="padding:20px 0px;">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-2" style="font-size:70px;">{{collectionNames.find(x=>x.id==artPieceCollection1).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton-tablet" style="font-size: 22px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection1)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_05.jpg`)+')'" style="width:50%; background-color:lightgray; padding:30px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div  class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <v-img :src="require(`../assets/ArtDetails/${artPieceCollection2IMGBig}`)" position="center" contain max-height="480" class="mt-8" />
                        <div style="padding:20px 0px;">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-2" style="font-size:70px;">{{collectionNames.find(x=>x.id==artPieceCollection2).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton-tablet" style="font-size: 22px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection2)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

		<footerComponent :backgroundImage="footerImage"></footerComponent>
    </v-container>

    <v-container  fluid style="padding:0px; margin-bottom: -128px;" class="text-center mobile-size"  v-if="dataReady && dataOk && isSmallScreen=='Mobile'">
        <v-row no-gutters class="text-center" justify="center">
            <div class="colorYellow poppinsLightFont px-1" style="font-size:12px; height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center;">
                <a class="cursorPointer uppercase mr-1 colorYellow" :href="'/'">
                    {{homeButtonLabel[languageId]}}
                </a>
                <span class="ml-1 mr-1" style="cursor: context-menu">/</span>
                <a class="cursorPointer uppercase ml-1 mr-1 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}`">
                    {{artPiecesButtonLabel[languageId]}}
                </a>
                <span class="ml-1 mr-1" style="cursor: context-menu">/</span>
                <a class="uppercase ml-1 colorYellow" :href="`/${baseArtPiecesUrl[languageId]}/${collectionLabel.split('|')[languageId].replace(/\s/g,' ')}`">
                    {{collectionLabel.split("|")[languageId].replace(/\s/g," ")}}
                </a>
                <span class="ml-1 mr-1" style="cursor: context-menu">/</span>
                <span class="uppercase ml-1 poppinsboldFont">
                    {{display.title.split("|")[languageId].replace(/\s/g," ")}}
                </span>
            </div>
        </v-row>
		<v-row no-gutters class="text-center" style="background-color:#F6F6E9; height:fit-content">
            <div style="display:flex; flex-direction: column; justify-content: center; padding:20px; width:100%">
                <div style="width:100%; display:flex; flex-direction: column; ">
                    <div class="mb-4" style="height:300px; width:100%; display:flex; align-items:center; justify-content:center;">
                        <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-right:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('back','selectedImage')">
                            &#60;
                        </a>
                        <v-img :src="require(`../assets/${selectedImage.base64}`)" position="center" contain max-height="300" style="position:relative;" />
                        <a class="colorWhite myriadProBoldFont noSelect" style="font-size:20px; margin-left:-25px; z-index:10; text-shadow: 1px 1px 2px black;" @click="changeImage('forward','selectedImage')">
                            &#62;
                        </a>
                    </div>
                    <div class="mb-2" style="height:auto; width:100%; display:flex; flex-direction: row; align-items:flex-start; justify-content:flex-start; overflow:auto;" v-if="!reloadDisplayImages">
                        <!-- <v-img class="mr-1"  v-for="(image,index) in displayImages()" :key="index" :src="require(`../assets/${image.base64}`)" position="center" contain max-height="150" max-width="150"  @click="selectImage(image,index)"/> -->
                        <img class="mr-1"  v-for="(image,index) in displayImages()" :key="index" :src="require(`../assets/${image.base64}`)" :alt="image.alt.includes('|')?image.alt.split('|')[languageId]:image.alt" position="center" @click="selectImage(image,index)" style="max-height: 150px; max-width: 150px;"/>
                    </div>
                    <div class="mt-2 mb-2" style="height:auto; width:100%;">
                        <v-form ref="form" lazy-validation >
                        <div style=" display:flex; flex-direction: column; align-items:center; justify-content:center; width:100%;">
                            <div class="mt-3" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:70px; padding:0px; margin-bottom:-25px;" v-if="!isComingSoon && isNew">{{newLabel[languageId]}}</span>
                                <h1 v-if="display.title!=undefined" class="colorYellow poppinsboldFont" style="font-size:24px; text-align:left;">{{display.title.split("|")[languageId].replace(/\s/g," ")}}</h1>
                                <h3 v-if="display.price!=undefined" class="mb-3 colorDarkBrown poppinsboldFont" style="font-size:20px;">$ {{(getPrice(display)* form.quantity).toLocaleString()}}</h3>
                            </div>
                            <div style=" display:flex; flex-direction: row; align-items:center;  width:100%;" v-if="!getOutOfStock()">
                                <v-text-field
                                    v-if="!isComingSoon"
                                    v-model="form.quantity"
                                    type="number"
                                    dense
                                    min=1 
                                    :max="getMaxProductionBySize()"
                                    class="form-control"
                                    style="max-width:50px;"
                                    @keydown="positiveQuantityValidator($event)"
                                ></v-text-field>
                                <v-btn v-if="!isComingSoon" color="secondary" @click="addToCart()" class="ml-2" >
                                    {{addToCartLabel[languageId]}}
                                </v-btn>
                            </div>
                            <div class="poppinsboldFont" style=" display:flex; flex-direction: row; align-items:center;  width:100%; color:#FF5C35; font-size:20px;" v-if="getOutOfStock()">
                                {{outOfStockLabel[languageId]}}
                            </div>
                            <div v-if="isComingSoon" class="mt-3" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:70px; padding:0px;">{{comingSoonLabel[languageId]}}</span>
                            </div>
                            <div v-if="!isComingSoon" class="mt-5" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select 
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.size"
                                            :items="artPieceSizes(display.sizes)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="sizeError?sizeErrorMessage[languageId]:[]"
                                            @click="sizeError=false"
                                            :style="`max-width:${(widthSize/2-30)}px;`"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectSize"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{sizeLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{sizeHint[languageId]}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.printedMedium"
                                            :items="artPiecesPrintedMediums(display.printedMediums)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="printedMediumError?printedMediumErrorMessage[languageId]:[]"
                                            @click="printedMediumError=false"
                                            :style="`max-width:${(widthSize/2-30)}px;`"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{printedMediumLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{printedMediumHint[languageId]}}</span>
                                </v-tooltip>
                            </div>
                            <div v-if="!isComingSoon" style=" display:flex; flex-direction: row; align-items:center; justify-content:space-between; width:100%;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.frame"
                                            :items="artPiecesFrames(display.frames)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="frameError?frameErrorMessage[languageId]:[]"
                                            @click="frameError=false"
                                            :style="`max-width:${(widthSize/2-30)}px;`"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectFrame"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{frameLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{frameHint[languageId]}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.liner"
                                            :items="artPiecesLiners(display.liners)"
                                            item-text="name"
                                            item-value="id"
                                            :label="linerLabel[languageId]"
                                            :error-messages="linerError?linerErrorMessage[languageId]:[]"
                                            @click="linerError=false"
                                            :style="`max-width:${(widthSize/2-30)}px;`"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="selectLiner"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{linerLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{linerHint[languageId]}}</span>
                                </v-tooltip>
                            </div>
                            <div v-if="display.price!=null">
                                <div style="border:1px solid #D79A2B; background-color:#ECECE4; width:100%; height:contain;" class="py-4">
                                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:55px;">{{simulatorTitle1[languageId]}}</h2>
                                    <p class="colorYellow poppinsRegularFont" style="font-size: 20px;text-transform:uppercase; margin:0px;">{{simulatorTitle2[languageId]}}</p>
                                    <p class="mb-8 colorGray poppinsRegularFont" style="font-size:16px;">{{simulatorTitle3[languageId]}}</p>
                                </div>
                                <div style="width:100%;margin-top:-18px;">
                                    <a href="/simulator">
                                        <v-btn color="secondary">{{simulatorButton[languageId]}}</v-btn>
                                    </a>
                                </div>
                            </div>
                            <div class="mt-5 poppinsExtraLightFont colorDarkGrey" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%; font-size:16px;">
                                <p style="text-align:left;" v-html="display.description.split('|')[languageId].replace(/\s/g,' ')"></p>
                            </div>

                        </div>
                        </v-form>
                    </div>
                    <div class="mt-4" style="height:auto; width:100%; ">
                        <div class="mr-4" style="background-color:white; height:fit-content; width:fit-content; display:flex; flex-direction: column; align-items:flex-start; justify-content:flex-start; padding:20px;">
                            <div class="colorDarkGrey poppinsExtraLightFont" style="display:flex; flex-direction:row;"><div class="poppinsSemiboldFont uppercase">{{skuLabel[languageId]}}: </div><div class="ml-2">{{display.id}}</div></div>
                            <div class="colorDarkGrey poppinsExtraLightFont" style="display:flex; flex-direction:row;"><div class="poppinsSemiboldFont uppercase">{{categoryLabel[languageId]}}: </div><div class="ml-2">{{display.type}}</div></div>
                            <div class="colorDarkGrey poppinsExtraLightFont" style="display:flex; flex-direction:row;"><div class="poppinsSemiboldFont uppercase">{{shareLabel[languageId]}}: </div>
                                <div class="ml-2">
                                    <a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/">
                                        <v-icon class="colorDarkBrown">
                                            mdi-instagram
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                                        <v-icon class="colorDarkBrown">
                                            mdi-facebook
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                                        <v-icon class="colorDarkBrown">
                                            mdi-pinterest
                                        </v-icon>
                                    </a>
                                    <a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                                        <v-icon class="colorDarkBrown">
                                            mdi-whatsapp
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:#F4F8F8; height:auto; padding:20px">
            <div style="width:100%; display:flex; flex-direction: column; justify-content: flex-start; align-items:flex-start;">
                <div class="colorYellow poppinsLightFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center; font-size:16px;">
                    <span class="cursorPointer uppercase mr-1" :class="historySelected?'poppinsboldFont':''" @click="historySelected=true; infoSelected=false; reviewSelected=false;">
                        {{littleHistoryLabel[languageId]}}
                    </span>
                    <span class="ml-1 mr-1" style="cursor: context-menu">/</span>
                    <span class="cursorPointer uppercase ml-1 mr-1" :class="infoSelected?'poppinsboldFont':''" @click="historySelected=false; infoSelected=true; reviewSelected=false;">
                        {{additionalInformationLabel[languageId]}}
                    </span>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:16px; padding-top:20px;" v-if="historySelected">
                    <p style="text-align:left;">{{display.history.split("|")[languageId].replace(/\s/g," ")}}</p>
                </div>
                <div class="colorDarkGrey poppinsExtraLightFont" style="font-size:16px; padding-top:20px;" v-if="infoSelected">
                    <div v-for="(data,index) in display.exif.split('|')" :key="index" >
                        <p style="text-align:left; margin:0px;" v-if="index==0"><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{exifDate}}</p>
                        <p style="text-align:left; margin:0px;" v-else><strong>{{exifLabel[index].split("|")[languageId]}}: </strong>{{data}}</p>
                    </div>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+spacesBackgroundImages[collectionId]+')'" style="background-color:cornsilk; height:auto; padding:20px">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto;" class="mb-4">
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:100%;" class="mb-2">
                    <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:80px; margin-bottom:-30px">{{idealLabel[languageId]}}</h2>
                    <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:30px;">{{spacesLabel[languageId]}}</h2>
                </div>
                <div style="display:flex; flex-direction: column; align-content:flex-start; text-align: start; width:100%;" class="mt-2">
                    <v-img v-if="selectMuckup()" :src="require(`../assets/${selectMuckup().base64}`)" position="center" contain max-height="380" :max-width="widthSize-40"/>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="background-color:white; height:auto; padding:20px; width:100%;">
            <div style="display:flex; flex-direction: column; align-items: flex-start; margin:auto; width:100%;" class="mb-2">
                <h2 class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:60px; margin-bottom:-30px; margin-top:-30px;">{{moreLabel[languageId]}}</h2>
                <h2 class="colorDarkBrown poppinsRegularFont uppercase" style="font-size:20px;">{{collectionsForYouLabel[languageId]}}</h2>
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; width:100%;" v-if="collectionNamesOk">
                    <div :style="'background-image: url('+require(`../assets/ArtDetails/${artPieceFeaturedCollectionIMGSmall}`)+'); ' + `height:${heightSize}px`" class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <div>
                            <h2 class="colorWhite lusciousLifestylesScriptFont ml-4" style="font-size:45px;">{{collectionNames.find(x=>x.id==artPieceFeaturedCollection).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                        </div>
                        <div style="width:100%; display:flex; justify-content:center;">
                        </div>
                        <div style="display:flex; justify-content:flex-end; align-items: flex-end;"  class="mr-2 mb-4 mt-4">
                            <a class="colorWhite poppinsBoldFont uppercase shopNowButton-mobile text-shadow background-darker" style="font-size: 12px; padding:5px 20px;" :href="goToCollection(artPieceFeaturedCollection)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                    <p class="colorDarkBrown poppinsRegularFont verticalText uppercase" style="font-size:14px; text-align:center; padding-right:5px; margin:auto;">{{featuredCollectionLabel[languageId]}}</p>
                </div>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center" style="height:auto;">
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_04.jpg`)+')'" style="width:100%; background-color:beige; padding:30px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div class="header backGroundImage"  style="display:flex; flex-direction: column; justify-content:space-between;">
                        <div style="width:100%; display:flex; justify-content:center;">
                            <v-img :src="require(`../assets/ArtDetails/${artPieceCollection1IMGSmall}`)" position="center" contain max-height="300" class="mt-8" />
                        </div>
                        <div style="padding:10px;" class="mb-2">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-4" style="font-size:45px;">{{collectionNames.find(x=>x.id==artPieceCollection1).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton-mobile" style="font-size: 16px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection1)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="backGroundImage" :style="'background-image: url('+require(`../assets/ArtDetails/Products_Desktop-Recovered_05.jpg`)+')'"  style="width:100%; background-color:lightgray; padding:30px">
                <div style="display:flex; flex-direction: row; align-content:flex-start; text-align: start; height:100%" v-if="collectionNamesOk">
                    <div class="header backGroundImage" style="display:flex; flex-direction: column; justify-content:space-between;">
                        <div style="width:100%; display:flex; justify-content:center;">
                            <v-img :src="require(`../assets/ArtDetails/${artPieceCollection2IMGSmall}`)" position="center" contain max-height="300" class="mt-8" />
                        </div>
                        <div style="padding:10px;" class="mb-2">
                            <h2 class="colorGreen lusciousLifestylesScriptFont mb-4" style="font-size:45px;">{{collectionNames.find(x=>x.id==artPieceCollection2).name.split("|")[languageId].replace(/\s/g," ")}}</h2>
                            <a class="colorGreen poppinsBoldFont uppercase shopNowButton-mobile" style="font-size: 16px; padding:5px 20px; border-color:#414A25 !important;" :href="goToCollection(artPieceCollection2)">{{shopNow[languageId]}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

		<footerComponent :backgroundImage="footerImage"></footerComponent>
    </v-container>

    <addedElementSnackbar :text="text" :snackbar="snackbar" @snackEnd="snackbar=false"></addedElementSnackbar>
</div>
</template>

<script>
import footerComponent from './components/Footer'
import addedElementSnackbar from './components/AddedElementSnackbar';
import { mapState, mapActions } from "vuex";
import axios from "axios";

const backgroundImage = require('../assets/Scapes and Panoramas/IMG_2729_L.webp')
const baseURL = process.env.VUE_APP_BASE_URL_API;
const artPieceCollection1 = process.env.VUE_APP_ART_PIECE_COLLECTION_1;
const artPieceCollection1IMGBig = process.env.VUE_APP_ART_PIECE_COLLECTION_1_IMAGE_BIG;
const artPieceCollection1IMGSmall = process.env.VUE_APP_ART_PIECE_COLLECTION_1_IMAGE_SMALL;
const artPieceCollection2 = process.env.VUE_APP_ART_PIECE_COLLECTION_2;
const artPieceCollection2IMGBig = process.env.VUE_APP_ART_PIECE_COLLECTION_2_IMAGE_BIG;
const artPieceCollection2IMGSmall = process.env.VUE_APP_ART_PIECE_COLLECTION_2_IMAGE_SMALL;
const artPieceFeaturedCollection = process.env.VUE_APP_ART_PIECE_COLLECTION_FEATURED;
const artPieceFeaturedCollectionIMGBig = process.env.VUE_APP_ART_PIECE_COLLECTION_FEATURED_IMAGE_BIG;
const artPieceFeaturedCollectionIMGSmall = process.env.VUE_APP_ART_PIECE_COLLECTION_FEATURED_IMAGE_SMALL;

	export default {
		name: 'ArtDetails',
        props: ['image', 'url', 'title', 'price', 'dialog'],
		components: { footerComponent, addedElementSnackbar },
        computed: {
            ...mapState("shoppingCart", 
                [
                    "languageId", "metricSystemId", "artPiecesOk", "propertiesOk", "images", "featuredCollectionLabel", "soldUnits",
                    "collectionNames", "collectionNamesOk", "shopNow", "comingSoonLabel", "newLabel",
                    "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel",
                    "size", "printedMedium", "frame", "liner", "artPieces", "shoppingCart", "themesNames", "themesNamesOk", "skuLabel",
                    "priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel","inStockLabel","outOfStockLabel","sizeErrorMessage","printedMediumErrorMessage","frameErrorMessage","linerErrorMessage","sizeHint","printedMediumHint","frameHint","linerHint","addToCartLabel",
                    "visitedForm","baseArtPiecesUrl",
                ]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
			heightSize(){
				var windowWidth = window.innerWidth;
				var height = 574;
				if(windowWidth <= 320)
					height = 320*.5
				if(windowWidth > 320 && windowWidth <= 640)
					height = windowWidth*.5
				if(windowWidth > 640 && windowWidth < 1024)
					height = 640*.5
				return height
			},
            imageSizeWidth(){
				var windowWidth = Math.round(0.721153846153846*(this.widthSize)-238.461538461538);
				return windowWidth;
            },
            imageSizeHeight(){
				var windowHeight = Math.round(0.5*(this.imageSizeWidth)+200);
                return windowHeight
            },
            maxImageSizeHeight(){
				var windowHeight = Math.round(0.319711538461538*(this.widthSize)+72.6153846153846);
                return windowHeight
            },
            dataReady(){
                return this.artPiecesOk && this.propertiesOk;
            }, 
            isNew(){
                return this.display.isNew;
            },
            isComingSoon(){
                return this.display.price == null
            },
            exifDate(){
                var stringDate = this.display.exif.split("|")[0].split("-")
                var date = new Date(stringDate.toString())
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                return date.toLocaleDateString(this.languageString,options);
            },
            languageString(){
                if(this.languageId == 0){
                    return 'es-ES';
                }
                if(this.languageId == 1){
                    return 'en-US';
                }
                return 'es-US'
            }
        },
        mounted() {
            if(this.dataReady){
                this.loadArtDetails();
            }
        },
        updated(){
            if(this.dataReady && !this.dataOk){
                this.loadArtDetails();
            }
            if(this.reloadDisplayImages){
                this.reloadDisplayImages = false;
            }
        },
		data: () => ({
			pageTitle:['',''],
			pageDescription:['',''],
            pageKeywords:['',''],
			pageMetaTitle:['',''],
            artPieceCollection1:artPieceCollection1,
            artPieceCollection1IMGBig:artPieceCollection1IMGBig,
            artPieceCollection1IMGSmall:artPieceCollection1IMGSmall,
            artPieceCollection2:artPieceCollection2,
            artPieceCollection2IMGBig:artPieceCollection2IMGBig,
            artPieceCollection2IMGSmall:artPieceCollection2IMGSmall,
            artPieceFeaturedCollection:artPieceFeaturedCollection,
            artPieceFeaturedCollectionIMGBig:artPieceFeaturedCollectionIMGBig,
            artPieceFeaturedCollectionIMGSmall:artPieceFeaturedCollectionIMGSmall,
			footerImage:require('../assets/About/About_Desktop_06.jpg'),
			backgroundImage:backgroundImage,
            collectionLabel:["Tema","Bricks, cities and towns"],
            categoryLabel:["Categoría", "Category"],
            shareLabel:["Compartir", "Share"],
            idealLabel:["ideal para todos", "ideal for all"],
            spacesLabel:["Tus espacios", "Your spaces"],
            littleHistoryLabel:["Una pequeña historia", "A little story"],
            additionalInformationLabel:["Información adicional", "Additional information"],
            reviewsLabel:["Reseñas", "Reviews"],
            moreLabel:["más", "more"],
            collectionsForYouLabel:["Collecciones para ti", "Collections for you"],

            exifLabel:["Fecha|Date","Ubicación|Location","Velocidad del obturador|Shutter Speed","Apertura|Aperture","Velocidad ISO|ISO Speed","Distancia Focal|Focal Length","Lente|Lens","Modelo de la Cámara|Camera Model"],

            simulatorTitle1: ["¿Quieres probar cómo","Do you want to see"],
            simulatorTitle2: ["se vería esta foto en tu casa?","this photo in your home?"],
            simulatorTitle3: ["Haz clic en iniciar y pruébalo.","Click on start to try out."],
            simulatorButton: ["Iniciar","Start"],

            spacesBackgroundImages:[
                require('../assets/ArtDetails/Products_Desktop-Recovered_05.jpg'),
                require('../assets/ArtDetails/Products_Desktop_01.jpg'),
                require('../assets/ArtDetails/Products_Desktop_02.jpg'),
                require('../assets/ArtDetails/Products_Desktop_03.jpg'),
                require('../assets/ArtDetails/Products_Desktop_04.jpg'),
                require('../assets/ArtDetails/Products_Desktop_05.jpg'),
                require('../assets/ArtDetails/Products_Desktop_06.jpg'),
                require('../assets/ArtDetails/Products_Desktop_07.jpg'),
                require('../assets/ArtDetails/Products_Desktop_08.jpg'),
                require('../assets/ArtDetails/Products_Desktop_09.jpg'),
                require('../assets/ArtDetails/Products_Desktop_10.jpg'),
                require('../assets/ArtDetails/Products_Desktop_11.jpg')
            ],

            collectionId:0,
            historySelected:true,
            infoSelected:false,
            reviewSelected:false,

            sizes:[{id:"",name:""}],
            printedMediums:[{id:"",name:""}],
            frames:[{id:"",name:""}],
            liners:[{id:"",name:""}],
            dataOk:false,
            soldDataOk:false,

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            form:{
                title:"",
                size:null,
                printedMedium:null,
                frame:null,
                liner:null,
                quantity:1,
                unitPrice:null,
            },
            sizeError:false,
            printedMediumError:false,
            frameError:false,
            linerError:false,
            quantityError:false,
            display:{},
            bugColor:"rgba(186, 19, 30, 0.8)",
            butterflyColor:"rgba(140, 108, 14, 0.7)",
            featherColor:"rgba(102, 26, 69, 1)",
            ghostColor:"rgba(31, 14, 9, 0.7)",
            globeColor:"rgba(118, 91, 140, 1)",
            kiwiColor:"rgba(95, 148, 92, 1)",
            cityColor:"rgba(176, 117, 93, 1)",
            infinityColor:"rgba(109, 129, 148, 1)",
            winterColor:"rgba(80, 161, 222, 1)",
            springColor:"rgba(56, 214, 95, 1)",
            summerColor:"rgba(252, 252, 111, 1)",

            autumnColor:"rgba(219, 136, 35, 1)",
            snackbar:false,
            text: '',

            selectedImage:'',
            selectedImageIndex: 0,
            firstFrameSelect:false,
            firstLinerSelect:false,
            metaImage:'',
            reloadDisplayImages:false,
		}),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'index, follow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
        methods:{
            ...mapActions("shoppingCart", ["addItemToCart", "filter", "setVisitedForm" ]),
			goToCollection(collection){
				this.filter({property:"themesFilter",with:collection});
				return `/${this.baseArtPiecesUrl[this.languageId]}`;
			},
			getCollectionImage(idCollection,selectedImage,direction){
				var artPieces = this.getArtPieces(idCollection,direction);
				var image = this.images.find(x=>x.idArtPiece == artPieces[selectedImage].id)
				return image.base64
			},
			getArtPieces(idCollection,direction="Landscape"){
				return this.artPieces.filter(x=>x.idCollection == idCollection && x.orientation == direction);
			},
            positiveQuantityValidator(event){
                return (event.key === '-' || event.key === '+' || event.key === '.' || event.key === ',' || event.key === 'e'  || event.key === 'E')? event.preventDefault() : null
            },
            selectImage(image,index){
                this.selectedImageIndex=index;
                this.selectedImage = image;
            },
            selectMuckup(){
                return this.images.find(x=>x.idArtPiece == this.selectedImage.idArtPiece && x.idImageType == 3);
            },
			changeImage(direction,selection){
				var images = this.displayImages();
				var currentImage = this.selectedImageIndex;
				if(direction=="back"){
					if(currentImage==0)
						currentImage = images.length-1;
					else
						currentImage -= 1
				}
				if(direction=="forward"){
					if(currentImage==images.length-1)
						currentImage = 0;
					else
						currentImage += 1
				}
                this.selectedImageIndex=currentImage;
				this[selection] = images[currentImage];
			},
            getSizeIndex(){
                var sizes = this.artPieceSizes(this.display.sizes)
                return sizes.indexOf(sizes.find(x=>x.id == this.form.size))
            },
            getMaxProductionBySize(){
                var soldUnitsNumber = 0;
                var soldUnits = this.soldUnits.find(x=>x.title == this.form.title)
                if(soldUnits != undefined){
                    var soldIndex = soldUnits.sizes.indexOf(this.form.size == null?'0':this.form.size.toString());
                    if(soldIndex != -1){
                        soldUnitsNumber = soldUnits.soldUnits[soldIndex];
                    }
                }
                return parseInt(this.display.productionQuantityBySize.split("|")[this.getSizeIndex()] - soldUnitsNumber)
            },
            getOutOfStock(){
                return this.display.outOfStock.split('|')[this.getSizeIndex()] == "true" || this.getMaxProductionBySize() < 1
            },
            artPieceSizes(sizes){
                return this.size.filter(x=>sizes.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.metricSystemId]}})
            },
            artPiecesPrintedMediums(printedMediums){
                return this.printedMedium.filter(x=>printedMediums.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesFrames(frames){
                return this.frame.filter(x=>frames.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesLiners(liners){
                return this.liner.filter(x=>liners.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            getPrice(artPiece){
                var size = this.form.size!=null? this.size.find(x=>x.id == this.form.size) : null
                var printedMediumPrice =  this.form.printedMedium!=null? this.printedMedium.find(x=>x.id == this.form.printedMedium).price : 0
                var framePrice =  this.form.frame!=null? this.frame.find(x=>x.id == this.form.frame).price : 0
                var linerPrice =  this.form.liner!=null? this.liner.find(x=>x.id == this.form.liner).price : 0
                var widthXHeight = size!=null? size.width * size.height: 0
                var sizePrice = size!=null? size.price : 0
                return Math.round(artPiece.price + sizePrice*widthXHeight + printedMediumPrice*widthXHeight + framePrice*widthXHeight + linerPrice*widthXHeight);
            },
            loadArtDetails(){
                axios.get(`${baseURL}ArtPieces/name?name=${encodeURIComponent(this.$route.params.pathMatch)}`)
                    .then(res => {
                        if(res.status == 200){
                            var collection = this.themesNames.find(x=>x.id==res.data.idTheme)
                            this.collectionId = collection.id;
                            this.collectionLabel = collection.name
                            var display = res.data
                            this.pageTitle = display.title?.split("|")
                            this.pageDescription = display.metaDescription?.split("|")
                            this.pageKeywords=display.metaTags?.split("|"),
                            this.pageMetaTitle=display.metaTitle?.split("|"),
                            this.display = display;
                            this.metaImage = this.selectMetaImage();
                            var inShoppingCart = this.shoppingCart.find(x=>x.title.includes(res.data.title))
                            if(inShoppingCart != undefined){
                                this.form = inShoppingCart;
                            }
                            else{
                                this.form = {
                                    title:display.title,
                                    size:parseInt(display.sizes.split("|")[0]),
                                    printedMedium:parseInt(display.printedMediums.split("|")[0]),
                                    frame:parseInt(display.frames.split("|")[0]),
                                    liner:parseInt(display.liners.split("|")[0]),
                                    quantity:1,
                                    unitPrice:this.getPrice(display),
                                }
                            }
                            this.dataOk = true;
                            if(this.visitedForm.title != display.title){
                                this.setVisitedForm({with:display.title,property:'title'})
                                this.setVisitedForm({with:null,property:'size'})
                            }
                            else{
                                this.firstLinerSelect = true;
                                this.firstFrameSelect = true;
                                this.reloadDisplayImages = true;
                                this.form.size = this.visitedForm.size;
                                this.form.liner = this.visitedForm.liner;
                                this.form.frame = this.visitedForm.frame;
                            }
                            this.selectedImage=this.displayImages(res.data.id)[0];
                        }
                    })
                    .catch(error => { 
                        console.log('sendRequest',error);
                    });
            },
            addToCart(){
                this.form.unitPrice = this.getPrice(this.display);
                this.validations();
                if(this.error) return;
                this.addItemToCart({property:"shoppingCart",with:this.form})
                this.text=this.form.title;
                this.snackbar = true;
            },
            validations(){
                this.error=false;
                if(this.form.size==null){
                    this.sizeError=true;
                    this.error=true;
                }
                else{
                    this.sizeError=false;
                }
                if(this.form.printedMedium==null){
                    this.printedMediumError=true;
                    this.error=true;
                }
                else{
                    this.printedMediumError=false;
                }
                if(this.form.frame==null){
                    this.frameError=true;
                    this.error=true;
                }
                else{
                    this.frameError=false;
                }
                if(this.form.liner==null){
                    this.linerError=true;
                    this.error=true;
                }
                else{
                    this.linerError=false;
                }
                if(this.form.quantity<=0){
                    this.form.quantity = 1;
                    this.quantityError=true;
                    this.error=true;
                }
                else{
                    this.quantityError=false;
                }
                if(this.form.quantity > this.getMaxProductionBySize()){
                    this.form.quantity = this.getMaxProductionBySize();
                    this.quantityError=true;
                    this.error=true;
                }
                else{
                    this.quantityError=false;
                }
            },
            selectSize(idSize){
                this.setVisitedForm({with:idSize,property:'size'})
            },
            selectFrame(idFrame){
                this.setVisitedForm({with:idFrame,property:'frame'})
                this.firstFrameSelect = true;
				var images = this.images.filter(x=>x.idArtPiece == this.display.id && (x.idImageType == 2 || x.idImageType == 4 || x.idImageType == 5));
                var filteredImages = images.filter(x=>(x.idFrame == idFrame && x.idLiner == this.form.liner) || x.idFrame == null);
                // this.selectedImage = filteredImages[0];
                this.selectedImage = filteredImages.filter(x=>x.idImageType == 5 || x.idImageType == 4).sort(x=>(x.idImageType < 5 ? 1 : -1))[0];
                this.selectedImageIndex = 0
            },
            selectLiner(idLiner){
                this.setVisitedForm({with:idLiner,property:'liner'})
                this.firstLinerSelect = true;
				var images = this.images.filter(x=>x.idArtPiece == this.display.id && (x.idImageType == 2 || x.idImageType == 4 || x.idImageType == 5));
                var filteredImages = images.filter(x=>(x.idFrame == this.form.frame && x.idLiner == idLiner) || x.idFrame == null);
                // this.selectedImage = filteredImages[0];
                this.selectedImage = filteredImages.filter(x=>x.idImageType == 5 || x.idImageType == 4).sort(x=>(x.idImageType < 5 ? 1 : -1))[0];
                this.selectedImageIndex = 0
            },
			getImageById(){
				var images = this.images.filter(x=>x.idArtPiece == this.display.id && (x.idImageType == 2 || x.idImageType == 4 || x.idImageType == 5));
                // var filteredImages = images.filter(x=>(x.idFrame == this.form.frame ) || x.idFrame == null);
				return images[0].base64
			},
            selectMetaImage(){
                return window.location.origin + require(`../assets/${this.getImageById()}`)
            },
            displayImages(){
                var images = this.images.filter(x=>x.idArtPiece == this.display.id && (x.idImageType == 2 || x.idImageType == 4 || x.idImageType == 5));
                if(this.firstFrameSelect || this.firstLinerSelect){
                    images = images.filter(x=>x.idFrame == this.form.frame || x.idFrame == null);
                }
                if(this.firstLinerSelect || this.firstFrameSelect){
                    images = images.filter(x=>x.idLiner == this.form.liner || x.idFrame == null)
                }
                return images 
            }
        }
	}
</script>

<style scoped>
a{
    text-decoration:none;
}
.cursorPointer:hover{
    cursor:pointer !important;
}
.verticalText{
	writing-mode:vertical-rl; 
	transform: rotate(180deg); 
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.shopNowButton{
	padding: 5px 50px;
	border: 1px solid #FEFEFE;
}
.shopNowButton-tablet{
	padding: 5px 50px;
	border: 1px solid #FEFEFE;
}
.shopNowButton-mobile{
	padding: 5px 20px;
	border: 1px solid #FEFEFE;
}
</style>
